var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.historyShow, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.historyShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.modelDataForm,
                "label-width": "150px",
                disabled: "",
              },
            },
            [
              _vm.type == "unrectify"
                ? [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "完成时间",
                                  prop: "rectifyDate",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    type: "date",
                                    placeholder: "请选择完成时间",
                                  },
                                  model: {
                                    value: _vm.modelDataForm.rectifyDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.modelDataForm,
                                        "rectifyDate",
                                        $$v
                                      )
                                    },
                                    expression: "modelDataForm.rectifyDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "实际整改人",
                                  prop: "actualRectifyUserName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请选择实际整改人" },
                                  model: {
                                    value:
                                      _vm.modelDataForm.actualRectifyUserName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.modelDataForm,
                                        "actualRectifyUserName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "modelDataForm.actualRectifyUserName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "整改措施", prop: "remark" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    autosize: { minRows: 2 },
                                    "show-word-limit": "",
                                    placeholder: "请填写整改要求",
                                  },
                                  model: {
                                    value: _vm.modelDataForm.remark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.modelDataForm, "remark", $$v)
                                    },
                                    expression: "modelDataForm.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "整改结果", prop: "prjAs" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "content" },
                                  _vm._l(
                                    _vm.modelDataFormList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.id,
                                          staticClass: "img-content",
                                          on: {
                                            click: function ($event) {
                                              return _vm.getNodeClick(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: item.thumbnailLink,
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.type == "finished"
                ? [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "验收结果",
                                  prop: "acceptFlag",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "y" },
                                    model: {
                                      value: _vm.modelDataForm.acceptFlag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelDataForm,
                                          "acceptFlag",
                                          $$v
                                        )
                                      },
                                      expression: "modelDataForm.acceptFlag",
                                    },
                                  },
                                  [_vm._v("核实通过")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "n" },
                                    model: {
                                      value: _vm.modelDataForm.acceptFlag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelDataForm,
                                          "acceptFlag",
                                          $$v
                                        )
                                      },
                                      expression: "modelDataForm.acceptFlag",
                                    },
                                  },
                                  [_vm._v("核实不通过")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "验收时间",
                                  prop: "acceptDate",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "date",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    placeholder: "请选择完成时间",
                                  },
                                  model: {
                                    value: _vm.modelDataForm.acceptDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.modelDataForm,
                                        "acceptDate",
                                        $$v
                                      )
                                    },
                                    expression: "modelDataForm.acceptDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "实际验收人",
                                  prop: "actualAcceptUserName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请选择实际验收人" },
                                  model: {
                                    value:
                                      _vm.modelDataForm.actualAcceptUserName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.modelDataForm,
                                        "actualAcceptUserName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "modelDataForm.actualAcceptUserName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "验收意见",
                                  prop: "acceptOpinion",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    autosize: { minRows: 2 },
                                    "show-word-limit": "",
                                    placeholder: "请填写验收意见",
                                  },
                                  model: {
                                    value: _vm.modelDataForm.acceptOpinion,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.modelDataForm,
                                        "acceptOpinion",
                                        $$v
                                      )
                                    },
                                    expression: "modelDataForm.acceptOpinion",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "验收现场", prop: "prjAs" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "content" },
                                  _vm._l(
                                    _vm.modelDataFormImgList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.id,
                                          staticClass: "img-content",
                                          on: {
                                            click: function ($event) {
                                              return _vm.getNodeClick(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: item.thumbnailLink,
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }