<template>
  <div>
    <el-container>
      <el-main>
        <head-layout
          head-title="任务下发列表"
        />
        <grid-head-layout
          ref="gridHeadLayout"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="handleCurrentChange"
          @page-size-change="handleSizeChange"
          @page-refresh-change="onLoad"
          @grid-row-detail-click="rowView"
          @grid-row-detail-click-distribution="rowDistribution"
        >
          <template #customBtn="{ row, index }">
            <el-button
              size="small"
              type="text"
              @click.stop="rowView(row)"
            >
              查看
            </el-button>
          </template>
        </grid-layout>
      </el-main>
    </el-container>
    <el-dialog
      :title="'任务下发信息查看'"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="isReportAllocationDialog"
      width="70%"
      top="10vh"
      @close="closeDialog"
    >
      <reportAllocationDialog ref="overseasProjectFillingEdit" :formData="formData" v-if="isReportAllocationDialog"
                                  @closeDialog="closeDialog"></reportAllocationDialog>
    </el-dialog>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonDialog from "@/components/CommonDialog";
import {getPage} from "@/api/reportDistribution";
import reportAllocationDialog
  from "@/views/business/reportManager/reportDistribution/distributionDialog.vue"

export default {
  components: {
    HeadLayout,
    GridLayout,
    CommonDialog,
    reportAllocationDialog
  },
  data() {
    return {
      defaultCheckedKeys: [],
      dicData: [],
      isReportAllocationDialog: false,
      formData: {},
      bpmnOption: {},
      query:{},
      circulationVisible: false,
      templateCode:"",
      bpmnVisible: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      tableLoading: false,
      searchColumns:[
        {
          placeholder: "请输入报表名称",
          prop: "templateName",
          span: 4,
        },
        {
          placeholder: "请选择下发状态",
          prop: "isEnable",
          type: "select",
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          dicData: [],
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 4,
        },
        {
          prop: "issuingTime",
          type: 'date',
          span: 4,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          placeholder: "请选择下发时间"
        },
      ]
    }
  },
  computed: {
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        selection: false,
        linklabel:"templateName",
        distributionLinklabel:["issuedNumber","completedNumber","unfinishedNumber"],
        menu:false,
        column: [
          {
            label: "报表名称",
            prop: "templateName",
            align: 'left',
            overHidden: true,
          },
          {
            label: "业务分类",
            prop: "reportCategoryCode",
            align: 'center',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=report_category",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "下发时间",
            prop: 'issuingTime',
            type: "date",
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
          },
          {
            label: "是否成功下发",
            prop: "isEnable",
            align: 'center',
            dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "填报单位",
            prop: "issuedNumber",
            align: 'center',
            overHidden: true,
          },
          {
            label: "已填报",
            prop: "completedNumber",
            align: 'center',
            overHidden: true,
          },
          {
            label: "未填报",
            prop: "unfinishedNumber",
            align: 'center',
            overHidden: true,
          },
          // {
          //   label: "补发次数",
          //   prop: "reissuesNumber",
          //   align: 'center',
          //   overHidden: true,
          // },
          // {
          //   label: "补发批次数",
          //   prop: "reissuesBatch  ",
          //   align: 'center',
          //   overHidden: true,
          // },
          // {
          //   label: "补发总数",
          //   prop: "reissuesNumberTotal",
          //   align: 'center',
          //   overHidden: true,
          // },
        ]
      }
    }
  },
  methods: {
    closeDialog() {
      this.isReportAllocationDialog = false
      this.onLoad(this.page)
    },
    rowView(row) {
      this.isReportAllocationDialog = true;
      this.formData = {
        row: JSON.stringify(row),
      }
    },
    rowDistribution(row,type){
      if(type == 'issuedNumber'){
        this.$router.push({
          path:'/business/reportManager/reportDistribution/add',
          query:{
            type: "",
            row: JSON.stringify(row),
          }
        })
      }else if(type == 'completedNumber'){
        this.$router.push({
          path:'/business/reportManager/reportDistribution/add',
          query:{
            type: "2",
            row: JSON.stringify(row),
          }
        })

      }else if(type == 'unfinishedNumber'){
        this.$router.push({
          path:'/business/reportManager/reportDistribution/add',
          query:{
            type: "1",
            row: JSON.stringify(row),
          }
        })

      }
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      if(this.templateCode){
        this.query.templateCode = this.templateCode;
      }
      this.page = page;
      getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data.records.map(item => ({
          ...item,
          isEnable: item.isEnable == "1" ? "1" : "0",
        }));
        this.tableData = data;
        this.$refs.gridLayOut.page.total = res.data.data.total;
        this.tableLoading = false;
      });
    },
  },
  created() {
    if(this.$route.query.templateCode){
      this.templateCode = this.$route.query.templateCode
    }
    this.onLoad(this.page);
  },

}
</script>

<style scoped>

</style>
