<template>
  <div>
    <el-dialog :title="title" :visible.sync="historyShow" width="80%">
      <el-form ref="dataForm" :model="modelDataForm" label-width="150px" disabled >
        <template v-if="type == 'unrectify'">
            <el-row>
              <el-col :span="8">
                <el-form-item label="完成时间" prop="rectifyDate">
                  <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" type="date"  v-model="modelDataForm.rectifyDate"  placeholder="请选择完成时间"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8" >
                <el-form-item label="实际整改人" prop="actualRectifyUserName">
                  <el-input v-model="modelDataForm.actualRectifyUserName"  placeholder="请选择实际整改人"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="整改措施" prop="remark">
                  <el-input type="textarea"  :autosize="{ minRows: 2}" show-word-limit v-model="modelDataForm.remark" placeholder="请填写整改要求" ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row >
            <el-col :span="24">
                <el-form-item label="整改结果" prop="prjAs">
                  <div class="content">
                    <div
                      v-for="(item, index) in modelDataFormList"
                      :key="item.id"
                      class="img-content"
                      @click="getNodeClick(item)"
                    >
                      <img :src="item.thumbnailLink" alt=""/>
                    </div>
                  </div>
                </el-form-item>
            </el-col>
            </el-row>
        </template>
        <template v-if="type == 'finished'">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="验收结果" prop="acceptFlag">
                    <el-radio v-model="modelDataForm.acceptFlag" label="y">核实通过</el-radio>
                    <el-radio v-model="modelDataForm.acceptFlag" label="n">核实不通过</el-radio>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="验收时间" prop="acceptDate">
                    <el-date-picker type="date" value-format="yyyy-MM-dd HH:mm:ss" v-model="modelDataForm.acceptDate"  placeholder="请选择完成时间"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8" >
                    <el-form-item label="实际验收人" prop="actualAcceptUserName">
                    <el-input v-model="modelDataForm.actualAcceptUserName"  placeholder="请选择实际验收人"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="验收意见" prop="acceptOpinion">
                    <el-input type="textarea"  :autosize="{ minRows: 2}" show-word-limit v-model="modelDataForm.acceptOpinion" placeholder="请填写验收意见" ></el-input>
                    </el-form-item>
                </el-col>
                </el-row>
                <el-row >
                <el-col :span="24">
                    <el-form-item label="验收现场" prop="prjAs">
                    <div class="content">
                        <div
                        v-for="(item, index) in modelDataFormImgList"
                        :key="item.id"
                        class="img-content"
                        @click="getNodeClick(item)"
                        >
                        <img :src="item.thumbnailLink" alt=""/>
                        </div>
                    </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </template>
      </el-form>
    </el-dialog>

    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
    data () {
        return {
            type: '',
            title: '',
            modelDataForm: {},
            modelDataFormList: [],
            historyShow: false,
            showFileDialog: false,
            fileUrl: '',
        }
    },

    methods: {
        init (data,type) {
            this.type = type
            this.title = type == 'unrectify'  ? '整改历史' : '验收历史'
            this.modelDataForm = data
            this.modelDataFormList=data.rectifyUrl && JSON.parse(data.rectifyUrl)
            this.historyShow = true
        },
        getNodeClick(node) {
            this.showFile(node);
        },

        showFile(item) {
        this.fileUrl =
          process.env.VUE_APP_BASE_KKFILEURL+"?url=" +
          encodeURIComponent(Base64.encode(item.link));
        this.showFileDialog = true;
        //exls表格预览隐藏打印按钮
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;

          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      },
    }

}
</script>

<style>

</style>