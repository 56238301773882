<template>
  <div style="height: 100%">
    <div class="checklist-edit">
      <div class="step-box">
        <head-layout :show-icon="false" head-title="处理进度"></head-layout>
        <el-steps direction="vertical" :active="active" style="margin-top: 30px;align-items: center;height: 50%;">
          <el-step title="上报"></el-step>
          <el-step title="评估"></el-step>
          <el-step title="整改"></el-step>
          <el-step title="验收"></el-step>
        </el-steps>
      </div>
      <div class="form-box">
        <div v-if="prepare" class="">
          <head-layout :head-title="headTitle" @head-cancel="headCancel" :head-btn-options="headBtnOptions"
                       @head-save="headSave(false)" @head-saveBack="headSave(true)"></head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form ref="dataForm" :model="dataForm" label-width="150px" :rules="formType == 'view' ? {} :rules"
                         :disabled="formType == 'view'">
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="隐患编号" prop="hdCode">
                        <el-input v-model="dataForm.hdCode" disabled placeholder="隐患编号自动生成"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="隐患地点" prop="location">
                        <el-input v-model="dataForm.location" placeholder="请填写隐患地点" :title="dataForm.location">
                          <template slot="append">
                            <div class="el-icon-map-location" style="width: 54px;text-align: center"
                                 @click="openMap()"></div>
                          </template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="项目名称" prop="organizationName">
                        <el-input v-model="dataForm.organizationName" ref="organizationName" placeholder="请选择被通知项目" :title="dataForm.organizationName" @focus="handleChangePrj">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="项目区域" prop="area">
                        <el-input v-model="dataForm.area" ref="areaName" placeholder="请选择项目区域" :title="dataForm.area" @focus="handleChangeArea">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="16" v-if="!isPrjUser">
                      <el-form-item label="隐患接收人" prop="assessmentUser">
                        <el-input v-model="dataForm.assessmentUserName" ref="assessmentUserName" disabled :title="dataForm.assessmentUserName">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="报告人" prop="reportUserName">
                        <el-input v-model="dataForm.reportUserName" @focus="handleUserModeOpen()"
                                  placeholder="请选择报告人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="报告部门" prop="reportDeptName">
                        <el-input v-model="dataForm.reportDeptName" @focus="handleDeptModeOpen()" :title="dataForm.reportDeptName"
                                  placeholder="请选择报告部门"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="报告时间" prop="reportDate">
                        <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm.reportDate"
                                        placeholder="请选择报告时间"></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="隐患描述" prop="hdDesc">
                       <div class="flex-container">
                         <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit maxlength="225"
                                   v-model="dataForm.hdDesc"
                                   placeholder="请填写隐患描述"></el-input>
                         <div class="hdDesc-mar">
                           <el-button type="primary" @click="handleDesc()">常见隐患</el-button>
                         </div>
                       </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="隐患来源"  prop="sourceType">
                        <avue-select :props="{
              label: 'dictValue',
              value: 'dictKey',
            }" v-model="dataForm.sourceType" placeholder="请选择隐患来源" type="tree"
                                     :dic="HD_SOURCE_TYPE"></avue-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="隐患照片" prop="treeData">
                        <el-upload
                          v-if="formType != 'view'"
                          accept=".jpg,.mp4,.png"
                          style="margin-bottom: 12px;display: flex; align-items: center;"
                          class="upload-demo"
                          action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km"
                          :show-file-list="false"
                          :before-upload="checkFileType"
                          :on-success="handleAvatarSuccess"
                          ref="uploadRef"
                          :headers="headers"
                        >
                          <el-button
                            size="small"
                            type="primary"
                            icon="el-icon-upload"
                          >点击上传
                          </el-button>
                        </el-upload>
                        <div class="content">
                          <div
                            v-for="(item, index) in treeData"
                            :key="item.id"
                            class="img-content"
                            @click="getNodeClick(item)"
                          >
                            <img :src="item.thumbnailLink" alt=""/>
                            <i
                              v-if="formType !== 'view'"
                              class="el-icon-circle-close delete-icon"
                              @click.stop="handleRemove(item, index)"
                            ></i>
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div v-if="unassess || isPrjUser" class="">
          <head-layout head-title="隐患评估"></head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form
                  ref="dataForm1"
                  :model="dataForm1"
                  label-width="150px"
                  :disabled="!dataForm.hdStatus=='prepare' || formType == 'view'"
                  :rules="formType == 'view'|| !isPrjUser ? {} : rules1"
                >
                  <el-row>
                    <!-- 隐患核实、责任部门、评估时间、验收人、验收部门页面不展示但需要传参 -->
                    <!-- 提交整改计划、整改通知书、出发通知书页面不展示 -->
                    <el-col :span="8" v-if="!isPrjUser && dataForm1.passFlag!='y'">
                      <el-form-item label="隐患核实" prop="passFlag">
                        <el-radio v-model="dataForm1.passFlag" label="y" :disabled="isPrjUser">核实通过</el-radio>
                        <el-radio v-model="dataForm1.passFlag" label="n" :disabled="isPrjUser">核实不通过</el-radio>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="隐患等级" prop="hdLevel">
                        <avue-select
                          :props="{
                            label: 'dictValue',
                            value: 'dictKey',
                          }"
                          v-model="dataForm1.hdLevel"
                          placeholder="请选择隐患等级"
                          type="tree"
                          :dic="hd_level"
                        ></avue-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="隐患类型" prop="hdType">
                        <avue-select
                          :props="{ label: 'dictValue', value: 'dictKey' }"
                          v-model="dataForm1.hdType"
                          placeholder="请选择隐患类型"
                          type="tree"
                          :dic="hd_type"
                        ></avue-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改单位" prop="rectifyDeptId">
                        <el-input v-if="!dataForm.hdStatus=='prepare' || formType == 'view'" v-model="dataForm1.rectifyDeptName"></el-input>
                        <div v-else class="flex-container">
                          <el-select v-model="dataForm1.rectifyDeptId" placeholder="请选择整改单位" @change="changeCompany">
                            <el-option v-for="item in rectifyDeptData" :key="item.dutyUnit" :value="item.dutyUnit" :label="item.dutyUnitName"></el-option>
                          </el-select>
                          <el-button size="mini" type="primary" class="hdDesc-mar" @click="handleChangeOrg">选择组织&人员</el-button>
                        </div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改人" prop="rectifyUser">
                        <el-input v-if="!dataForm.hdStatus=='prepare' || formType == 'view'" v-model="dataForm1.rectifyUserName"></el-input>
                         <el-select v-model="dataForm1.rectifyUser" filterable placeholder="请先选择整改单位" v-else @change="changeThreeUser">
                            <el-option v-for="item in rectifyDeptData" :key="item.dutyUser" :value="item.dutyUser" :label="item.dutyUserName" ></el-option>
                         </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                      <el-form-item label="整改期限" prop="rectifyLimitDate">
                        <el-date-picker
                          type="date"
                          :picker-options="pickerOptionsStart"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="dataForm1.rectifyLimitDate"
                          placeholder="请选择整改期限"
                        ></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="24"
                      v-if="dataForm1.passFlag == 'y'"
                      key="rectifyRequest"
                    >
                      <el-form-item label="整改要求" prop="rectifyRequest">
                        <el-input
                          type="textarea"
                          maxlength="500"
                          :autosize="{ minRows: 2 }"
                          show-word-limit
                          v-model="dataForm1.rectifyRequest"
                          placeholder="请填写整改要求"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="24"
                      v-if="dataForm1.passFlag == 'n'"
                      key="remark"
                    >
                      <el-form-item label="备注" prop="remark">
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 2 }"
                          show-word-limit
                          v-model="dataForm1.remark"
                          placeholder="请填写备注"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div v-if="unrectify" class="unrectify">
          <head-layout head-title="隐患整改" :head-btn-options="[]">
            <template slot="right">
              <el-dropdown >
                <el-button type="primary" size="mini">
                  整改历史
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="handleDropdown(item,'unrectify')" v-for="item in historyList" :key="item.id" class="list-decoration">{{item.rectifyDate}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form ref="dataForm2" :model="dataForm2" label-width="150px" disabled>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="完成时间" prop="rectifyDate">
                        <el-date-picker type="date" value-format="yyyy-MM-dd HH:mm:ss"
                                        v-model="dataForm2.rectifyDate"
                                        placeholder="请选择完成时间"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="整改措施" prop="remark">
                        <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit v-model="dataForm2.remark"
                                  placeholder="请填写整改要求"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="整改结果" prop="prjAs">
                        <div class="content">
                          <div
                            v-for="(item, index) in treeData4"
                            :key="item.id"
                            class="img-content"
                            @click="getNodeClick(item)"
                          >
                            <img
                              :src="item.link || item.thumbnailLink"
                              alt=""
                            />
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div v-if="finished" class="">
          <head-layout head-title="隐患验收" :head-btn-options="[]">
            <template slot="right">
              <el-dropdown>
                <el-button type="primary" size="mini">
                  验收历史
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="handleDropdown(item,'finished')" v-for="item in finishedHistoryList" :key="item.id" class="list-decoration">{{item.acceptDate}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </head-layout>
          <div class="formContentBox">
            <div class="formMain">
              <div style="padding: 0 12px">
                <el-form ref="dataForm3" :model="dataForm3" label-width="150px" disabled>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="验收结果" prop="acceptFlag">
                        <el-radio v-model="dataForm3.acceptFlag" label="y">核实通过</el-radio>
                        <el-radio v-model="dataForm3.acceptFlag" label="n">核实不通过</el-radio>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="验收时间" prop="acceptDate">
                        <el-date-picker type="date" value-format="yyyy-MM-dd HH:mm:ss"
                                        v-model="dataForm3.acceptDate"
                                        placeholder="请选择完成时间"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="实际验收人" prop="actualAcceptUserName">
                        <el-input v-model="dataForm3.actualAcceptUserName"
                                  placeholder="请选择实际验收人"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="验收意见" prop="acceptOpinion">
                        <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit
                                  v-model="dataForm3.acceptOpinion" placeholder="请填写验收意见"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="验收现场" prop="prjAs">
                        <div class="content">
                          <div
                            v-for="(item, index) in treeData5"
                            :key="item.id"
                            class="img-content"
                            @click="getNodeClick(item)">
                            <img :src="item.thumbnailLink" alt=""/>
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="选择位置" :append-to-body="false" :visible.sync="mapBox" top="7vh">
      <div style="width: 100%">
        <!--        <BaiduMap @changePosition="changePostion" :point="dataForm.location" @clickEvent="click" @getLocalAddress="getLocalAddr"></BaiduMap>-->
        <GaodeMap v-if="mapBox" ref="GaodeMap" @selectAddress="selectAddress" @getLocation="getLocation"></GaodeMap>
      </div>
      <span slot="footer">
        <el-button size="small" @click="celMap">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" @click="handleMapSave">
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="userShow" width="80%" top="8vh">
      <UserDetpDialog ref="UserDetpDialog" @select-data="selectData"></UserDetpDialog>
    </el-dialog>
    <el-dialog title="部门选择" :visible.sync="deptShow1" width="80%">
      <DeptDialog ref="DeptDialog" @select-data="selectData1"></DeptDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <sortSetting ref="sortSetting" @callback="callback"></sortSetting>
    <selectProjectDialogVue ref="selectProjectDialog" @changeProject="changeProject"></selectProjectDialogVue>
    <el-dialog title="项目区域选择" :visible.sync="areaDialog" width="80%">
      <prjArea ref="prjArea"  @prjAreaCallback="prjAreaCallback" dialogType />
    </el-dialog>
    <el-dialog title="整改人选择" :visible.sync="userDialog" width="80%" top="5vh">
      <rectifyDataOrg ref="rectifyDataOrg" @callback="orgUserCallback" :prjId=dataForm.organizationId />
    </el-dialog>

    <historyDialog ref="historyDialog"></historyDialog>
  </div>
</template>

<script>
import * as  API from "@/api/riskitemView/checklist";
import website from "@/config/website";
import {detail} from "@/api/riskitemView/checklist";
import {HD_SOURCE_TYPE} from "@/api/riskitemView/evaluate";
import snows from "@/util/snows";
import {dateFormat} from "@/util/date";
// import BaiduMap from "@/views/system/attendance/BaiduMap";
import GaodeMap from "@/views/system/attendance/GaodeMap"; 
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog.vue";
import {mapGetters} from "vuex";
import {getToken} from "@/util/auth";
 import sortSetting from '@/views/business/safetyTtandard/sortSetting/model.vue'
import selectProjectDialogVue from '@/views/business/safe/blacklistOrganization/components/selectProjectDialog.vue';
import prjArea from '@/views/business/safe/itemList/projectArea/index.vue';
import { prjstructureuserDetail, prjthirduserPage, prjthirdcompanyPage, prjthirduserList } from "@/api/safe/itemList";
import {prjAreaUserPage,prjAreaUserList} from '@/api/safe/projectArea.js';
import {List} from "@/api/riskitemView/rectify";
// import prjUserList from './prjUserList.vue'
import rectifyDataOrg from './rectifyDataOrg.vue'
import * as acceptAPI from "@/api/riskitemView/acceptance";
import historyDialog from './historyDialog.vue'
import { roleIds, getUsersByRoleIdAndDeptId  } from "@/api/system/client";
import {belowStandardDetail} from '@/api/safetyAndEnvironmentalProtection/oneJob'

export default {
  // 专业负责人、负责单位的传参为暂时保留，不做逻辑参考--20240901
  components: {
    DeptDialog,
    UserDetpDialog,
    GaodeMap,
    sortSetting,
    selectProjectDialogVue,
    prjArea,
    // prjUserList,
    rectifyDataOrg,
    historyDialog,
    // BaiduMap
  },
  data() {
    return {
      userShow: false,
      deptShow1: false,
      showFileDialog: false,
      mapBox: false,
      fileUrl: '',
      respUserShow: false,
      historyShow: false,
      modelDataForm: {},
      modelDataFormImgList: [],
      dataForm1: {},
      dataForm2: {},
      dataForm3: {},
      treeData: [],
      treeData1: [],
      treeData2: [],
      treeData3: [],
      treeData4: [],
      treeData5: [],
      sourceDic: [],
      hd_level: [],
      HD_SOURCE_TYPE: [],
      hd_type: [],
      formType: '',
      headTitle: '',
      // 默认为中材国际大厦经纬度
      mapdata: {
        longitude: '116.481752',
        latitude: '40.01681'
      },
      hdStatus: '',
      dataForm: {
        reportDate: dateFormat(new Date()),
      },
      rules: {
        treeData: [{
          required: true,
          message: '请上传隐患现场图片',
          trigger: 'blur'
        }],
        hdDesc: [{
          required: true,
          message: '隐患描述不能为空',
          trigger: ['blur','change']
        }],
        reportDate: [{
          required: true,
          message: '报告时间不能为空',
          trigger: 'change'
        }],
        reportUserName: [{
          required: true,
          message: '报告人不能为空',
          trigger: 'blur'
        }],
        organizationName: [{
          required: true,
          message: '被通知项目不能为空',
          trigger: 'change'
        }],
        area: [{
          required: true,
          message: "项目区域不能为空",
          trigger: "change",
        }],
        assessmentUser: [{
          required: true,
          message: "请配置隐患接收人"
        }]
      },
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      hd_status: [],
      selectionList: [],
      active: 1,
      prepare: false,//草稿
      unassess: false,//待评估
      unrectify: false,//待整改
      finished: false,//完成
      areaDialog: false,
      userDialog: false,
      isPrjUser: false,
      rules1: {
        acceptUser: [
          {
            required: true,
            message: "请选择验收人",
            trigger: "change",
          },
        ],
        respDeptName: [
          {
            required: true,
            message: "请选择责任部门",
            trigger: "change",
          },
        ],
        rectifyUserName: [
          {
            required: true,
            message: "请选择整改人",
            trigger: "change",
          },
        ],
        acceptUserName: [
          {
            required: true,
            message: "请选择验收人",
            trigger: "change",
          },
        ],
        rectifyDeptName: [
          {
            required: true,
            message: "请选择整改部门",
            trigger: "change",
          },
        ],
        acceptDeptName: [
          {
            required: true,
            message: "请选择验收部门",
            trigger: "change",
          },
        ],
        respUserName: [
          {
            required: true,
            message: "请选择责任人",
            trigger: "change",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入备注",
            trigger: "blur",
          },
        ],
        rectifyRequest: [
          {
            required: true,
            message: "请输入整改要求",
            trigger: "blur",
          },
        ],
        hdLevel: [
          {
            required: true,
            message: "请选择隐患等级",
            trigger: "change",
          },
        ],
        acceptDept: [
          {
            required: true,
            message: "请选择验收部门",
            trigger: "change",
          },
        ],
        rectifyLimitDate: [
          {
            required: true,
            message: "请选择整改期限",
            trigger: "blur",
          },
        ],
        rectifyDeptId: [
          {
            required: true,
            message: "请选择整改部门",
            trigger: "change",
          },
        ],
        rectifyUser: [
          {
            required: true,
            message: "请选择整改人",
            trigger: "change",
          },
        ],
        hdDesc: [
          {
            required: true,
            message: "请输入项目描述",
            trigger: "blur",
          },
        ],
        assessmentTime: [
          {
            required: true,
            message: "请选择评估时间",
            trigger: "change",
          },
        ],
      },
      historyShow:false,
      modelDataForm:{},
      modelDataFormImgList:[],
      modelDataFormList:[],
      historyList: [],
      deptChangeType: '', // 部门选择类型
      userChangeType: '', // 人员选择
      areaUserType: false, // 专业责任人来源，true: 区域配置人员, false: 系统人员
      postDicCode: '',
      finishedHistoryList: [], // 验收历史
      // threeOrganizationData: [], // 相关方组织列表
      rectifyDeptData: [], // 整改单位下拉数据--来源：项目区域设置
      threeUserData: [], // 对应人员信息
      orjChangePrj: true, // 是否总部人员选择项目
    }
  },
  computed: {
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
    ...mapGetters([
      'userInfo'
    ]),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "暂存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
        result.push({
          label: "提交并返回",
          emit: "head-saveBack",
          type: "button",
          btnOptType: "refer",
        });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
  },
  async mounted() {
    this.dataForm.id = this.$route.query.id;
    let type = this.$route.query.type;
    this.formType = type;
    if (type == 'edit') {
      this.headTitle = '隐患信息';
      this.getPrjbasicinfoDetail();
    } else if (type == 'view') {
      this.headTitle = '隐患信息';
      this.getPrjbasicinfoDetail();
    } else {
      if(this.$route.query.securityInspectionId){
        // 来源：一岗双责-不合格记录
        this.orjChangePrj = false
        this.dataForm.securityInspectionId = this.$route.query.securityInspectionId
        this.dataForm.sourceType = '2'
        this.getInspectionInfo(this.dataForm.securityInspectionId)
      } else {
        let deptCategory = this.userInfo.deptCategory || this.userInfo.dept_Category
        if(this.userInfo.company_id && this.userInfo.company_id != -1) {
            this.$set(this.dataForm, 'organizationId', this.userInfo.company_id)
            this.$set(this.dataForm, 'organizationName', this.userInfo.company_name)
            this.orjChangePrj = false
            this.getPrjUser(this.userInfo.company_id,'prj')
        } else if (deptCategory == 5) { // 兼容组织为项目数据 
          this.$set(this.dataForm, 'organizationId', this.userInfo.dept_id)
          this.$set(this.dataForm, 'organizationName', this.userInfo.dept_name)
          this.orjChangePrj = false
          this.getPrjUser(this.userInfo.dept_id,'prj')
        }
      }
      this.$set(this.dataForm, 'reportDeptId', this.userInfo.dept_id)
      this.$set(this.dataForm, 'reportDeptName', this.userInfo.dept_name)
      this.$set(this.dataForm, 'reportUser', this.userInfo.user_id)
      this.$set(this.dataForm, 'reportUserName', this.userInfo.real_name)
      this.hdStatus = 'prepare'
      this.headTitle = '新增隐患信息';
      this.hdStatusShow()
    }
    await this.getCode('HD_SOURCE_TYPE')
    await this.getCode('hd_status')
    await this.getCode('hd_level')
    await this.getCode('hd_type')
  },
  methods: {
    handleDesc(){
      this.$refs.sortSetting.initTree()
    },
    callback(val){
      this.$set(this.dataForm,'hdDesc',val.content)
    },
    handleRemove(item, index) {
      this.treeData.splice(index, 1);
    },
    selectData(row,deptId,deptName) {
      if(this.userChangeType == 'respUser'){
        let data = {
              userId: row.id,
              userName: row.realName,
              deptId,
              deptName,
        }
        this.pgUserInfo(data)
      } else {
        this.dataForm.reportUser = row.id
        this.dataForm.reportUserName = row.realName
      }
      this.userShow = false
      console.log("进行输出人员-------------------", row,deptId,deptName)
    },
    selectData1(row) {
      if(this.deptChangeType == 'rectifyDeptName') {
        // 整改单位
        this.$set(this.dataForm1, "rectifyDeptId", row.id);
        this.$set(this.dataForm1, "rectifyDeptName", row.deptName)
      } else {
        // 报告部门
        this.dataForm.reportDeptId = row.id
        this.dataForm.reportDeptName = row.deptName
      }
      this.deptShow1 = false
      console.log("进行输出人员-------------------", row)
    },
    handleUserModeOpen(type) {
      this.userChangeType = type
      this.userShow = true
    },
    handleDeptModeOpen(type) {
      this.deptChangeType = type
      this.deptShow1 = true
    },
    handlePreview(file) {
      snows.downloadFile1(file.link, file.name)
    },
    hdStatusShow() {
      if (this.hdStatus == 'prepare') {
        this.prepare = true
        this.active = 1
      } else if (this.hdStatus == 'unassess') {
        this.prepare = true
        // this.unassess = true
        this.active = 1
      } else if (this.hdStatus == 'canceled') {
        this.prepare = true
        this.unassess = true
        this.active = 1
      } else if (this.hdStatus == 'unrectify') {
        this.prepare = true
        this.unassess = true
        // this.unrectify = true
        this.active = 2
      } else if (this.hdStatus == 'unaccept') {
        this.prepare = true
        this.unassess = true
        this.unrectify = true
        // this.finished = true
        this.active = 3
      } else if (this.hdStatus == 'finished') {
        this.prepare = true
        this.unassess = true
        this.unrectify = true
        this.finished = true
        this.active = 4
      }
    },
    async getCode(code) {
      await API.HD_SOURCE_TYPE(code).then(res => {
        this[code] = res.data.data
      })
    },
    checkFileType(file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG = (fileType === '.jpg' || fileType == '.png' || fileType == '.mp4');

      if (!isJPG) {
        this.$message.warning('上传图片只能是 .jpg  .png .mp4 格式!');
      }
      if (!isLt20M) {
        this.$message.warning('上传文件大小不能超过 20MB!');
      }
      return isJPG && isLt20M;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.treeData.push({
        extension: file.raw.type,
        id: file.response.data.attachId,
        link: file.response.data.link,
        thumbnailLink: file.response.data.thumbnailLink,
        name: file.response.data.originalName,
        originalName: file.response.data.name,
      });
      this.$set(this.dataForm, 'treeData', this.treeData)
      this.$refs.dataForm.validateField('treeData')
    },
    // 根据ID查表单详情
    getPrjbasicinfoDetail() {
      detail({
        id: this.dataForm.id
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data;
          this.hdStatus = res.data.data.hdStatus;
          if (res.data.data.hdAssessmentVO) {
            this.dataForm1 = res.data.data.hdAssessmentVO;
          }
          if (res.data.data.hdRectifyVO) {
            this.dataForm2 = {...res.data.data.hdRectifyVO};
          }
          if (res.data.data.hdAcceptVO) {
            this.dataForm3 = res.data.data.hdAcceptVO;
          }
          this.treeData =this.dataForm.hdUrl ? JSON.parse(this.dataForm.hdUrl) :[]
          this.$set(this.dataForm, 'treeData', this.treeData)
          if (res.data.data.hdAssessmentVO.assessmentUrl) {
            this.treeData1 = JSON.parse(res.data.data.hdAssessmentVO.assessmentUrl)
          }
          if (res.data.data.hdAssessmentVO.punishUrl) {
            this.treeData2 = JSON.parse(res.data.data.hdAssessmentVO.punishUrl)
          }
          if (res.data.data.hdRectifyVO.rectifyPlanUrl) {
            this.treeData3 = JSON.parse(res.data.data.hdRectifyVO.rectifyPlanUrl)
          }
          if (res.data.data.hdRectifyVO.rectifyUrl) {
            this.treeData4 = JSON.parse(res.data.data.hdRectifyVO.rectifyUrl)
          }
          if (res.data.data.hdAcceptVO.acceptUrl) {
            this.treeData5 = JSON.parse(res.data.data.hdAcceptVO.acceptUrl)
          }
          this.getHistoryData() // 获取整改历史
          this.getFinishedHistoryData() // 获取验收历史
          this.getPrjUser(this.dataForm.organizationId,this.dataForm.createUser)
          this.hdStatusShow()
          if(this.dataForm.hdStatus == 'prepare' && this.formType == 'edit') {
            this.getPrjAreaUser(this.dataForm.areaId)
          }
          // if(this.dataForm1.rectifyDeptId){
          //   this.getThreeData('rectifyUser', this.dataForm1.rectifyDeptId)
          // }
        }
      })
    },
    //获取经纬度
    openMap() {
      if (this.formType == 'view') return
      this.mapBox = true
    },
    changePostion(lat, lng) {
      this.mapdata = {
        longitude: lng,
        latitude: lat
      }
    },
    click(lng, lat) {
      this.mapdata = {
        longitude: lng,
        latitude: lat
      }
    },
    getLocalAddr(lat, lng) {
      if (['add'].includes(this.type)) {
        this.mapdata = {
          longitude: lng,
          latitude: lat
        }
      }
    },
    celMap() {
      this.mapBox = false;
      this.$set(this.dataForm, 'location', '');
      this.$set(this.dataForm, 'latitude', '');
    },
    handleMapSave() {
      // 高德地图用
      this.mapBox = false;

      // 百度地图用
      // if(!this.myGeo ){
      //   this.myGeo = new BMap.Geocoder()
      // }
      // let that=this
      // this.myGeo.getLocation(new BMap.Point(this.mapdata.longitude,this.mapdata.latitude), (point) => {
      //   if (point) {
      //     that.$set(that.dataForm,'location',point.address)
      //     that.$set(that.dataForm,'latitude',this.mapdata.longitude+this.mapdata.latitude)
      //     that.mapBox = false
      //   }
      // }, '全国');
    },
    // 高德地图用
    selectAddress(data) {
      this.$set(this.dataForm, 'location', data.address);
      this.$set(this.dataForm, 'latitude', data.lng + ',' + data.lat);
    },
    // 高德地图用
    getLocation(data) {
      this.$set(this.dataForm, 'location', data.address);
      this.$set(this.dataForm, 'latitude', data.lng + ',' + data.lat);
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    headSave(boolean) {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if(this.formType == 'add'){
            this.dataForm.createUser = this.userInfo.user_id
            this.dataForm.createDept = this.userInfo.dept_id
          }
          if(!this.dataForm.id && this.isPrjUser){
            this.$refs.dataForm1.validate((valid) => {
              if(valid){
                this.dataForm.hdAssessmentVO = {...this.dataForm1}
                this.handleSubmit(boolean)
              }
            })
          } else {
            this.handleSubmit(boolean)
          }
        }
      });
    },
    handleSubmit (boolean) {
      this.$loading();
      let url = this.dataForm.id ? API.update : API.save
      url({
        taskId:this.$route.query.taskId,
        proType:this.$route.query.proType,
        ...this.dataForm,
        hdStatus: boolean&&this.isPrjUser ? "unrectify" : boolean ?  'unassess' : this.dataForm.hdStatus ? this.dataForm.hdStatus : 'prepare',
        hdUrl: JSON.stringify(this.treeData),
      }).then((res) => {
        if (res.data.code == 200) {
          // if (boolean) {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
          // }
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.dataForm.id = res.data.data;
          this.getPrjbasicinfoDetail();
          this.$loading().close();
        }
      }).catch(err => {
        this.$loading().close();
      })
    },
    getNodeClick(node) {
      this.selectNode = node;
      this.showFile(node);
    },
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    
    // 选择项目
    handleChangePrj () {
      // console.log(this.orjChangePrj)
      this.$refs.organizationName.blur()
      if(this.orjChangePrj){
        this.$refs.selectProjectDialog.init()
      }
    },
    changeProject (data) {
      this.$set(this.dataForm, 'organizationName', data.prjName)
      this.$set(this.dataForm, 'organizationId', data.id)
      this.getPrjUser(data.id,'add')
    },

    getPrjUser (prjId, type) {
      let userId = type=='add' ? this.userInfo.user_id : type
      // this.getThreeData('company', prjId)
      if(type == 'prj') {
        this.isPrjUser = true
        this.dataForm1.passFlag = 'y'
        this.dataForm1.acceptUser = this.userInfo.user_id
        this.dataForm1.acceptUserName = this.userInfo.real_name
        this.dataForm1.acceptDept = this.userInfo.dept_id
        this.dataForm1.acceptDeptName = this.userInfo.dept_name 
      } else {
        prjstructureuserDetail({prjId,userId}).then(res => {
          let data = res.data.data
          if(data && data.id){
            this.isPrjUser = true
            if(type == 'add'){
              this.dataForm1.passFlag = 'y'
              this.dataForm1.acceptUser = this.userInfo.user_id
              this.dataForm1.acceptUserName = this.userInfo.real_name
              this.dataForm1.acceptDept = this.userInfo.dept_id
              this.dataForm1.acceptDeptName = this.userInfo.dept_name 
              // this.getCheckHistory(this.dataForm.organizationId)         
            }
          } else {
            this.isPrjUser = false
            if(this.formType == 'add'){
              roleIds({roleNames:'隐患接收人',tenantId:this.userInfo.tenant_id}).then(res => {
                if(res.data.code == 200){
                  let roleId = res.data.data
                  if(roleId && typeof roleId == "string"){
                    getUsersByRoleIdAndDeptId({deptId: prjId, roleId, needChildren:'yes'}).then(result => {
                      let data = result.data.data
                      this.dataForm.assessmentDept = prjId
                      if(typeof data === "object" && Array.isArray(data)){
                        let assessmentUser = data.map(val => val.id).toString()
                        this.$set(this.dataForm, 'assessmentUser', assessmentUser)
                        let assessmentUserName = data.map(val => val.realName).toString()
                        this.$set(this.dataForm, 'assessmentUserName', assessmentUserName)
                      } else {
                        this.$message.warning("请设置该项目隐患接收人")
                      }
                    })
                  }
                } else {
                  this.$message.warning("系统未设置隐患接收人，请联系管理员")
                }
              })
            }
          }
        })
      }
    },

    handleChangeArea(){
      this.$refs.areaName.blur()
      if(!this.dataForm.securityInspectionId){
        if(this.dataForm.organizationId){
          this.areaDialog = true
          this.$nextTick(()=>{
            this.$refs.prjArea.init(this.dataForm.organizationId,'view')
          })
        } else {
          this.$message.warning("请先选择项目")
        }
      }
    },
    prjAreaCallback (data) {
      this.$set(this.dataForm, 'areaId', data.id)
      this.$set(this.dataForm, 'areaCode', data.areaCode)
      this.$set(this.dataForm, 'area', data.areaName)
      if(this.isPrjUser){
        this.getPrjAreaUser(data.id)
      }
      this.areaDialog = false
    },

    getPrjAreaUser (prjAreaId) {
      prjAreaUserPage(1,1000,{prjAreaId}).then((res) => {
          const data = res.data.data
          this.rectifyDeptData = data.records
          // this.areaUserType = data.total > 1 
          // this.postDicCode = data.total > 1 ? data.records[0].postName : ''
          if(data.total) {
            const userData = data.records[0]
            let infoData = {
              userId: userData.dutyUser,
              userName: userData.dutyUserName,
              deptId: userData.dutyUnit,
              deptName: userData.dutyUnitName,
            }
            this.pgUserInfo(infoData)
          }
        });
    },

    // 整改历史
    getHistoryData () {
      List({
          hdId: this.dataForm.id,
          size:9999,
          current:1,
        }).then(res=>{
          this.historyList=res.data.data.records
        })
    },
    handleDropdown(item,type){
      this.$refs.historyDialog.init(item, type)
    },

    // 验收历史
    getFinishedHistoryData () {
      acceptAPI.List({
          hdId: this.dataForm.id,
          size:9999,
          current:1,
        }).then(res=>{
          this.finishedHistoryList=res.data.data.records
        })
    },

    // 获取相关方数据
    getThreeData (type, val) {
      // if(type == 'company') {
      //   prjthirdcompanyPage({prjId: val}).then((res) => {
      //     if ((res.data.code = 200)) {
      //       this.threeOrganizationData=res.data.data
      //     }
      //   });
      // } else if (type == 'rectifyUser') {
      //   prjthirduserList({thirdFlg:1,prjId: this.dataForm.organizationId,deptId: val}).then((res) => {
      //     this.threeUserData = res.data.data;
      //   });
      // }
    },

    changeCompany (val) {
      const data = this.rectifyDeptData.find(v=>v.dutyUnit == val)
      this.$set(this.dataForm1, 'rectifyDeptId', val)
      this.$set(this.dataForm1, 'rectifyDeptName', data.dutyUnitName)
      this.$set(this.dataForm1, 'rectifyUser', data.dutyUser)
      this.$set(this.dataForm1, 'rectifyUserName', data.dutyUserName)
      this.rectifyDeptData = this.rectifyDeptData.filter(val=> val.type!=='dialog')
    },

    changeThreeUser (val) {
      const data = this.rectifyDeptData.find(v=>v.dutyUser == val)
      this.$set(this.dataForm1, 'rectifyDeptId', data.dutyUnit || '')
      this.$set(this.dataForm1, 'rectifyDeptName', data.dutyUnitName || '')
      this.$set(this.dataForm1, 'rectifyUser', val)
      this.$set(this.dataForm1, 'rectifyUserName', data.dutyUserName || '')
      this.rectifyDeptData = this.rectifyDeptData.filter(val=> val.type!=='dialog')
    },

    getInspectionInfo (id) {
      belowStandardDetail({id}).then(res=>{
        let data = res.data.data
        this.treeData = data.photos ? JSON.parse(data.photos) :[]
        this.$set(this.dataForm, 'treeData', this.treeData)
        this.isPrjUser = true
        this.$set(this.dataForm, 'organizationId', data.projectId) // 项目
        this.$set(this.dataForm, 'organizationName', data.projectName)
        this.$set(this.dataForm, 'areaId', data.regionId) // 区域
        this.$set(this.dataForm, 'areaCode', data.regionCode)
        this.$set(this.dataForm, 'area', data.regionName)
        this.$set(this.dataForm, 'hdDesc', data.inspectionOpinion) // 隐患描述
        
        this.$set(this.dataForm1, 'passFlag', 'y')
        // this.getThreeData('company', data.projectId)
        this.$set(this.dataForm1, 'acceptUser', this.userInfo.user_id) // 验收人
        this.$set(this.dataForm1, 'acceptUserName', this.userInfo.real_name)
        this.$set(this.dataForm1, 'acceptDept', this.userInfo.dept_id) // 验收部门
        this.$set(this.dataForm1, 'acceptDeptName', this.userInfo.dept_name)
      })
    },

    handleChangeOrg () {
      this.userDialog = true
    },
    orgUserCallback (row) {
      this.userDialog = false
      let data = {
        userId: row.userId,
        userName: row.userName,
        deptId: row.deptId,
        deptName: row.deptName,
      }
      this.pgUserInfo(data)
      // 为了方便展示，添加一条数据到下拉数据中(先删除前一条由弹窗加入的数据)
      this.rectifyDeptData = this.rectifyDeptData.filter(val=> val.type!=='dialog')
      this.rectifyDeptData.push({
        dutyUser: row.userId,
        dutyUserName: row.userName,
        dutyUnit: row.deptId,
        dutyUnitName: row.deptName,
        type: 'dialog'
      })
    },

    pgUserInfo (row) {
      this.$set(this.dataForm1, "respUser", row.userId); // 责任人
      this.$set(this.dataForm1, "respUserName", row.userName);
      this.$set(this.dataForm1, "rectifyUser", row.userId); // 整改人userId
      this.$set(this.dataForm1, "rectifyUserName", row.userName);
      this.$set(this.dataForm1, "respDeptId", row.deptId); // 责任部门
      this.$set(this.dataForm1, "respDeptName", row.deptName);
      this.$set(this.dataForm1, "rectifyDeptId", row.deptId); // 整改单位
      this.$set(this.dataForm1, "rectifyDeptName", row.deptName);
    }
  }
}

</script>
<style lang="scss">

.checklist-edit {
  display: flex;
  height: 100%;
  justify-content: space-between;

  .formContentBox {
    padding: 12px;
    height: calc(100% - 76px);
  }

  .el-input-group__append {
    padding: 0;
  }

  .formMain {
    width: 100%;
    overflow: auto;
    height: 100%;
  }

  .step-box {
    background-color: #ffffff;
    width: 125px;
  }

  .form-box {
    height: 100%;
    overflow: scroll;
    background-color: #ffffff;
    width: calc(100% - 135px);
  }

  .formTopic {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgb(187, 187, 187);
    font-weight: 600 !important;
    font-size: 18px;
  }

  .shuttleBackBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;

    .el-tabs {
      width: 100%;
    }

    .table-box {
      width: calc(100% - 280px);
    }
  }

  .shutleBack {
    width: 33%;
    background: #ffffff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
  }

  .leftRightBtn {
    margin: 211px 12px;
  }

  .shutleTitle {
    width: 100%;
    background-color: #f5f7fa;
    height: 40px;
    line-height: 40px;
    text-indent: 12px;
    border-bottom: 1px solid #ebeef5;
    font-size: 16px;
  }

  .shutContent {
    padding: 6px;
    height: 400px;
    overflow: auto;
  }

  .deptBox {
    line-height: 40px;
    color: #333333;
    border-bottom: 1px solid #ebeef5;
    font-size: 13px;
    display: flex;
  }

  .unitBox {
    width: 100%;
    display: flex;
    background-color: #f5f7fa;
    border-bottom: 1px solid #ebeef5;
  }

  .unitTitle {
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    font-size: 16px;
    cursor: pointer;
    border-right: 1px solid #ebeef5;
  }

  .content {
    display: flex;
    align-items: center;

    .img-content {
      width: 188px;
      height: 100px;
      padding: 3px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 12px;

      .delete-icon {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 16px;
        color: red;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

  }
}
.prjUser-class{
  height: 100%;
}
</style>
